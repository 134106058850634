/* postcss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
}

.animate-wiggle {
    animation: wiggle 0.5s ease-in-out infinite;
}


.dark .themeSwitch.active > svg {
    fill: white !important;
}

/* Style the react-select container */
.react-select-container {
    /*@apply relative inline-block w-full rounded-md bg-white border border-gray-300 focus-within:border-blue-500 focus-within:ring focus-within:ring-blue-200 focus-within:ring-opacity-50;*/
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
    /*@apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:ring-slate-500 dark:bg-slate-600 dark:text-slate-300  dark:hover:bg-slate-600*/
}

/*.react-select__control::before, .react-select__control::after {*/
/*    @apply dark:border-slate-500;*/
/*}*/

.react-select__control {
    @apply dark:border-slate-500;

}
/*.react-select-container::before, .react-select-container::after {*/
/*    @apply dark:border-slate-500;*/
/*}*/


.react-select__control {
    @apply dark:bg-slate-600 border-0 ring-0;
    /*@apply flex items-center justify-between relative w-full p-2;*/
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
}

.react-select__control--is-focused:hover, .react-select__control--is-focused {
    @apply dark:border-slate-500;
    @apply dark:shadow-slate-300;
    @apply dark:shadow-none;
}
.react-select__multi-value__label {
    @apply dark:text-white;
}

.react-select__multi-value__remove svg {
    @apply dark:fill-gray-300;
}
.react-select__placeholder {
    @apply dark:text-gray-300;
}
.react-select__value-container {

    /*@apply flex items-center justify-start w-full pr-2 truncate;*/
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
}

.react-select__indicators {
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
}

.react-select__menu {
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
    @apply dark:bg-slate-600 rounded-md;
}

.react-select__menu-list {
    @apply dark:bg-slate-600 rounded-md;
    /*background-color: red !important;*/
}

.react-select__option:hover {
    @apply dark:bg-slate-500;
}

.react-select__option--is-selected {
    @apply dark:bg-slate-500;
}

.react-select__option--is-focused {
    @apply dark:bg-slate-500;
}

.react-select__option {
    /*@apply bg-red-600;*/
    /*background-color: red !important;*/
    @apply text-black;
    @apply dark:text-white;
    @apply text-sm;
}

.react-select__input-container {
    @apply text-black;
    @apply dark:text-white
    /*@apply: dark:text-white text-black !important;*/
}

.react-select__multi-value  {
    @apply dark:bg-slate-500;
}

.react-select__input {
    outline: none;
    box-shadow: none !important;
    @apply: dark:text-white;
}

.react-select__value-container {
    @apply dark:bg-slate-600 border-0 ring-0;
}


/*!* Style the react-select control *!*/
/*.react-select__control {*/
/*    !* Apply Tailwind CSS utility classes to style the control *!*/
/*    @apply flex items-center justify-between relative w-full p-2;*/

/*    !* Style the react-select value container *!*/
/*    .react-select__value-container {*/
/*        !* Apply Tailwind CSS utility classes to style the value container *!*/
/*        @apply flex items-center justify-start w-full pr-2 truncate;*/
/*    }*/

/*    !* Style the react-select indicators *!*/
/*    .react-select__indicators {*/
/*        !* Apply Tailwind CSS utility classes to style the indicators *!*/
/*        @apply flex items-center justify-start pr-2;*/

/*        !* Style the indicator icons *!*/
/*        .react-select__indicator {*/
/*            !* Apply Tailwind CSS utility classes to style the indicator icons *!*/
/*            @apply text-gray-400 cursor-pointer;*/

/*            !* Style the hover state of the indicator icons *!*/
/*            &:hover {*/
/*                 !* Apply Tailwind CSS utility classes to style the hover state *!*/
/*                 @apply text-gray-500;*/
/*             }*/
/*        }*/
/*    }*/
/*}*/